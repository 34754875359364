/* eslint-disable max-len */
export const rootConfig = {
  apiRoot: "https://travelteachergroup.com:8081/",
  base_url: "https://travelteachergroup.com:8081/",
  image_url: "https://travelteachergroup.com:8081/uploads/",
  // apiRoot: "http://127.0.0.1:8000/api/",
  // base_url: "http://127.0.0.1:8000"
  // app_url: "https://serviceora.com",
  // local_url: "http://localhost:3000",
};

export const apiEndPoints = {

  login: `${rootConfig.apiRoot}admin/signin`,
  userLogout: `${rootConfig.apiRoot}user/logout`,
  getProfile: `${rootConfig.apiRoot}admin/profile/preview`,

  getDashboardResult : `${rootConfig.apiRoot}admin/dashboardCount`,

  /*** Passenger List */
  passengerList: `${rootConfig.apiRoot}admin/customer/list`,
  passengerPreview: `${rootConfig.apiRoot}admin/customer/preview`,
  passengerStatus: `${rootConfig.apiRoot}admin/customer/status`,
  passengerSuspend: `${rootConfig.apiRoot}admin/customer/suspend`,
  passengerRideList: `${rootConfig.apiRoot}admin/customer/booking/list`,
  passengerRideBooking: `${rootConfig.apiRoot}admin/customer/ride/invoice`,
  /*** Passenger List */
  /** Driver List */
  driverList: `${rootConfig.apiRoot}admin/driver/list`,
  driverPreview: `${rootConfig.apiRoot}admin/driver/preview`,
  driverStatus: `${rootConfig.apiRoot}admin/driver/status`,
  driverSuspend: `${rootConfig.apiRoot}admin/driver/suspend`,
  driverRideList: `${rootConfig.apiRoot}admin/driver/booking/list`,
  driverRideBooking: `${rootConfig.apiRoot}admin/driver/ride/invoice`,
  driverDocumentStatus: `${rootConfig.apiRoot}admin/driver/document/approve`,
  driverVehicleStatus: `${rootConfig.apiRoot}admin/driver/vehicle/approve`,
  /** Driver List */

  /*** Document  */
  
  documentCreate: `${rootConfig.apiRoot}admin/document/create`,
  documentList: `${rootConfig.apiRoot}admin/document/list`,
  documentStatus: `${rootConfig.apiRoot}admin/document/status`,
  /*** Document  */

  supportticketList: `${rootConfig.apiRoot}admin/support/ticket/list`,


  supportreasonList: `${rootConfig.apiRoot}admin/support_reason/list`,
  supportreasonStatus: `${rootConfig.apiRoot}admin/support_reason/status`,
  supportreasonCreate: `${rootConfig.apiRoot}admin/support_reason/create`,

  

  cancelreasonList: `${rootConfig.apiRoot}admin/cancel_reason/list`,
  cancelreasonStatus: `${rootConfig.apiRoot}admin/cancel_reason/status`,
  cancelreasonCreate: `${rootConfig.apiRoot}admin/cancel_reason/create`,

  


  vehicleList: `${rootConfig.apiRoot}admin/vehicle/brand/list`,
  vehicleStatus: `${rootConfig.apiRoot}admin/vehicle/brand/status`,
  vehicleCreate: `${rootConfig.apiRoot}admin/vehicle/brand/create`,

  vehicleModelList: `${rootConfig.apiRoot}admin/vehicle/model/list`,
  vehicleModelStatus: `${rootConfig.apiRoot}admin/vehicle/model/status`,
  vehicleModelCreate: `${rootConfig.apiRoot}admin/vehicle/model/create`,
  

  vehicleTypeList: `${rootConfig.apiRoot}admin/vehicle/type/list`,
  vehicleTypeStatus: `${rootConfig.apiRoot}admin/vehicle/type/status`,
  vehicleTypeCreate: `${rootConfig.apiRoot}admin/vehicle/type/create`,
  
  
  /***Owner List */
  ownerList: `${rootConfig.apiRoot}admin/owner/list`,
  ownerPreview: `${rootConfig.apiRoot}admin/owner/preview`,
  ownerStatus: `${rootConfig.apiRoot}admin/owner/status`,
  ownerSuspend: `${rootConfig.apiRoot}admin/owner/suspend`,
  /***Owner List */
  /*** Booking List */
  bookingList: `${rootConfig.apiRoot}admin/booking/list`,
  bookingInvoice: `${rootConfig.apiRoot}admin/booking/invoice`,
  /*** Booking List */

  

  // Check Current City Api
  currentCityApi: `https://nominatim.openstreetmap.org/reverse?format=json`,

  


};

export const firebase = {
  apiKey: "AIzaSyC9S5wCQqw7skqeCG01ct465ogSfEf20W8",
  authDomain: "serviceora-6117f.firebaseapp.com",
  projectId: "serviceora-6117f",
  storageBucket: "serviceora-6117f.appspot.com",
  messagingSenderId: "41898727243",
  appId: "1:41898727243:web:30668acc8692d281b8face",
  measurementId: "G-VFTKWGKPCC",

  // O8e945XdMmolagfXHIMnCdS-19xvZJ_iTseKTVit92w
  //Vapid Key
  vapidKey: "BGLEsTnwlvbwjEpy1niRAY5R6QRXw0OMJPMO3q-Ei5JkKZE15ls9FsPe4PD_xHJXp7M55hi0rkz6evhpo0iPwvg"

}
