import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { VehicleModelCreators } from "./store";
import { VehicleCreators } from "../Vehicle/store";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import Sidebar from "../Section/Sidebar";
import Footer from "../Section/Footer";
import toast, { Toaster } from "react-hot-toast";
import _ from "lodash";
import moment from "moment";
import Loader from "../../../../Common/Loader/Loader";
import SwitchComponent from "../../../../Common/SwitchComponent/SwitchComponent";
import formatPhoneNumber from "../../../../Common/PhoneNumber/PhoneNumber";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { WebService } from "../../../../services/axios/webServices";
import Modal from "../../../../Common/Modal/Modal"
import { getBase64 } from "../../../../shared/utility/getBase64";
import Select from "react-select";

export const VehicleModel = () => {
  const userData = getUserDataFromLocal();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Pagination state
  const [startValue, setStartValue] = useState(0)
  const [lastValue, setLastValue] = useState(0)

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10); // Number of items per page
  const [totalPages, setTotalPages] = useState(0);

  const [isMediumModalOpen, setIsMediumModalOpen] = useState(false);
  const closeMediumModal = () => setIsMediumModalOpen(false);
  const [isInputData, setIsInputData] = useState({})

  const { vehicleModelLoading, vehicleModelList } = useSelector((state) => state.vehicleModelData);
  const { vehicleLoading, vehicleList } = useSelector((state) => state.vehicleData);
  


  // console.log('hello', vehicleModelList)

  // Fetch document list on component mount
  // useEffect(() => {
  //   dispatch(VehicleModelCreators.getVehicleModelList({ startValue: 0, lastValue: 10 }));
  //   dispatch(VehicleCreators.getVehicleList({ startValue: 0, lastValue: 10 }));
  // }, [dispatch]);
  useEffect(() => {
    const startValue = (currentPage - 1) * pageSize;
    const lastValue = startValue + pageSize;
    setStartValue(startValue)
    setLastValue(lastValue)
    // Dispatch API call with startValue and lastValue
    dispatch(VehicleModelCreators.getVehicleModelList({ startValue, lastValue }));
    dispatch(VehicleCreators.getVehicleList({ startValue: 0, lastValue: 10 }));

    // Set total pages based on the data from the API response (mocked for now)
    // Example: Update this logic based on your actual data structure
    setTotalPages(20); // Replace with API response total page count if available
  }, [currentPage, pageSize, dispatch]);

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const updateStatus = async (ele) => {
    try {
        const response = await WebService.put(
            apiEndPoints.vehicleModelStatus,
            JSON.stringify({ model_id: ele }),
            {
              headers: {
                token: `${userData.token}`,
              },
            }
          );
          if (response.status === 200) {
            toast.success(response.data.msg, {
                position: "top-right",
            });
            dispatch(VehicleModelCreators.getVehicleModelList({ startValue: 0, lastValue: 10 }));
          } else if (response.response.status === 404) {
            toast.error(response.response.data.msg, {
                position: "top-right",
            });
            dispatch(VehicleModelCreators.getVehicleModelList({ startValue: 0, lastValue: 10 }));
          }
          else {
            toast.error('something went wrong', {
                position: "top-right",
            });
          }
    } catch (error) {
      console.error("Failed to update status:", error);
    }
  };

  const options = vehicleList?.map(item => ({
    "value": item?._id,
    "label": item?.name
  }))

  const handleChangeVehicle = (e) => {
    setIsInputData({ ...isInputData, vehicle_brand_id: e?.value })


  }

  const handleShowItem = () => {
    // e.preventDefault()
    setIsMediumModalOpen(true)
  }

  const handleChange = (e) => {
    // console.log('e', e)
    const { name, value } = e.target;
    setIsInputData({ ...isInputData, [name]: value });
  }

  function handleImageChange(e) {
    const { name, value } = e.target;
    getBase64(e.target.files[0]).then((result) => {
      // console.log('yes', isInputData)
      setIsInputData({
        ...isInputData, [name]: result,
      });
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log('input', isInputData)
      // dispatch(updateProfileCreators.updateProfile({ updateInput }));
    handleSubmitForm();
  };

  const handleSubmitForm = async(req, res, next,) => {
    try {
      // console.log('response', isInputData)
      // return false;
      const response = await WebService.post(
          apiEndPoints.vehicleModelCreate,
          JSON.stringify(isInputData),
          {
            headers: {
              token: `${userData.token}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success(response.data.msg, {
              position: "top-right",
          });
          setIsMediumModalOpen(false)
          dispatch(VehicleModelCreators.getVehicleModelList({ startValue: 0, lastValue: 10 }));
        } else if (response.response.status === 404) {
          toast.error(response.response.data.msg, {
              position: "top-right",
          });
          dispatch(VehicleModelCreators.getVehicleModelList({ startValue: 0, lastValue: 10 }));
        }
        else {
          toast.error('something went wrong', {
              position: "top-right",
          });
        }
    } catch (error) {
      console.error("Failed to update status:", error);
    }
  }


  

  return (
    <>
      <Toaster />
      <Sidebar />
      <Modal
            isOpen={isMediumModalOpen}
            onClose={closeMediumModal}
            title={`Add Vehicles Model`}
            size="Medium"
            footer={
                <>
                    <button onClick={closeMediumModal} style={{ background: 'gray', color: 'white' }}>
                        Close
                    </button>
                </>
            }
        >
            <div className="box box-primary">
            <div className="box-header with-border">
              {/* <h3 className="box-title">Quick Example</h3> */}
            </div>
            <form onSubmit={handleSubmit}>
              <div className="box-body">
                <div className="form-group">
                  <label htmlFor="brand_name">Brand Name</label>
                  <Select
                      name="vehicle_brand_id"
                      options={options}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={handleChangeVehicle}
                    />
                </div>
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <input type="text" className="form-control" id="name" placeholder="Enter name" name="name"
                      onChange={handleChange}/>
                </div>
                <div className="form-group">
                  <label htmlFor="file_input">File input</label>
                  <input type="file" id="file_input" onChange={handleImageChange} name="logo"/>

                  {/* <p className="help-block">file input .png, </p> */}
                </div>
              </div>

              <div className="box-footer">
                <button type="submit" className="btn btn-primary">Submit</button>
              </div>
            </form>
          </div>
      </Modal>
      <div className="content-wrapper">
        <section className="content-header">
          <h1>
            All VehicleModel
            <small>List of VehicleModel</small>
          </h1>
          <ol className="breadcrumb">
            <li>
              <Link to="/dashboard">
                <i className="fa fa-dashboard"></i> Home
              </Link>
            </li>
            <li className="active">All VehicleModel</li>
          </ol>
        </section>

        <section className="content">
          <div className="row">
            <div className="col-xs-12">
              <div className="box" style={{ height: "500px", overflowY: "auto" }}>
                <div className="box-header">
                  <h3 className="box-title">List of All VehicleModel</h3>
                  <button type="button" className="btn btn-info btn-sm pull-right"
                  onClick={() => handleShowItem()} 
                  >
                      <i className="fa fa-plus"></i>&nbsp;
                      Add Model
                  </button>
                </div>
                <div className="box-body">
                  {vehicleModelLoading ? (
                    <div className="overlay">
                      <i className="fa fa-refresh fa-spin"></i>
                    </div>
                  ) : (
                    <table id="example2" className="table table-bordered table-hover">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Model Name</th>
                          <th>Brand Name</th>
                          <th>Type</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vehicleModelList?.map((item, index) => (
                          <tr key={item?._id || index}>
                            <td>{index + 1}</td>
                            <td>
                              <div className="user-block" key={index + 1}>
                                  <img className="img-circle" src={(item?.image == 0) ? rootConfig.base_url + 'uploads/no_image.png' : rootConfig.base_url + 'uploads/' + item?.image} alt="User Image" />
                                  <span className="username"><Link to="#">{_.startCase(_.toLower(item?.name))}</Link></span>
                                  <span className="description">Register - {moment(item?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</span>
                              </div>
                            </td>
                            <td>
                              <div className="user-block">
                                <img className="img-circle" src={(item?.vehicle?.image == 0) ? rootConfig.base_url + 'uploads/no_image.png' : rootConfig.base_url + 'uploads/' + item?.vehicle?.image} alt="User Image" /> &nbsp; { _.startCase(_.toLower(item?.vehicle?.name)) }
                              </div>
                            </td>
                            <td>{ item?.type }</td>
                            <td>
                            <SwitchComponent
                                checked={item?.status === "Active" ? true : false}
                                onChange={() => updateStatus(item?._id)}
                            />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
                <div className="box-footer">
                  <div className="pagination-container">
                    <nav aria-label="Page navigation">
                      <ul className="pagination justify-content-center">
                        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                          <button
                            className="page-link"
                            onClick={handlePrevious}
                            disabled={currentPage === 1}
                          >
                            Previous
                          </button>
                        </li>
                        <li className="page-item disabled">
                          <span className="page-link">
                            Page {currentPage} of {totalPages}
                          </span>
                        </li>
                        <li
                          className={`page-item ${currentPage === totalPages ? "disabled" : ""
                            }`}
                        >
                          <button
                            className="page-link"
                            onClick={handleNext}
                            disabled={currentPage === totalPages}
                          >
                            Next
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default VehicleModel;
