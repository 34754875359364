import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Creators } from "../../../auth/store";
import { OwnerCreators } from "./store";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import Footer from "../Section/Footer";
import Sidebar from "../Section/Sidebar";
import { Toaster, toast } from "react-hot-toast";
import _ from "lodash";
import moment from 'moment';
import Loader from "../../../../Common/Loader/Loader";
import Modal from "../../../../Common/Modal/Modal"
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import ModalComponent from "../../../../Common/Modal/Modal";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import formatPhoneNumber from "../../../../Common/PhoneNumber/PhoneNumber";
import { WebService } from "../../../../services/axios/webServices";
import SwitchComponent from "../../../../Common/SwitchComponent/SwitchComponent";




export const Owner = () => {

    const userData = getUserDataFromLocal();
    const navigate = useNavigate();
    const dispatch = useDispatch(); 

    // Pagination state
    const [startValue, setStartValue] = useState(0)
    const [lastValue, setLastValue] = useState(0)

    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10); // Number of items per page
    const [totalPages, setTotalPages] = useState(0);


    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const [isPassengerObj, setIsPassengerObj] = useState({})

    const { ownerLoading, ownerList } = useSelector((state) => state.ownerData);

    console.log('ownerList', ownerList)


    // useEffect(() => {
    //     dispatch(OwnerCreators.getOwnerList({ "startValue": 0, "lastValue": 10 }));
        
    // }, []);

    useEffect(() => {
        const startValue = (currentPage - 1) * pageSize;
        const lastValue = startValue + pageSize;
        setStartValue(startValue)
        setLastValue(lastValue)
        // Dispatch API call with startValue and lastValue
        dispatch(OwnerCreators.getOwnerList({ startValue, lastValue }));

        // Set total pages based on the data from the API response (mocked for now)
        // Example: Update this logic based on your actual data structure
        setTotalPages(20); // Replace with API response total page count if available
    }, [currentPage, pageSize, dispatch]);

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
        }
    };


    const handleShowItem = async(item) => {
        
        openModal(item)
    }

    const updateStatus = async (ele) => {
        try {
            const response = await WebService.post(
                apiEndPoints.ownerStatus,
                JSON.stringify({ owner_id: ele }),
                {
                  headers: {
                    token: `${userData.token}`,
                  },
                }
              );
            //   console.log('response', response)
              if (response.status === 200) {
                toast.success(response.data.msg, {
                    position: "top-right",
                });
                dispatch(OwnerCreators.getOwnerList({ startValue: startValue, lastValue: lastValue }));
              } else if (response.response.status === 404) {
                toast.error(response.response.data.msg, {
                    position: "top-right",
                });
                dispatch(OwnerCreators.getOwnerList({ startValue: startValue, lastValue: lastValue }));
              }
              else {
                toast.error('something went wrong', {
                    position: "top-right",
                });
              }
        } catch (error) {
          console.error("Failed to update status:", error);
        }
      };

      const updateSuspend = async (ele) => {
        try {
            const response = await WebService.post(
                apiEndPoints.ownerSuspend,
                JSON.stringify({ owner_id: ele }),
                {
                  headers: {
                    token: `${userData.token}`,
                  },
                }
              );
            //   console.log('response', response)
              if (response.status === 200) {
                toast.success(response.data.msg, {
                    position: "top-right",
                });
                dispatch(OwnerCreators.getOwnerList({ startValue: startValue, lastValue: lastValue }));
              } else if (response.response.status === 404) {
                toast.error(response.response.data.msg, {
                    position: "top-right",
                });
                dispatch(OwnerCreators.getOwnerList({ startValue: startValue, lastValue: lastValue }));
              }
              else {
                toast.error('something went wrong', {
                    position: "top-right",
                });
              }
        } catch (error) {
          console.error("Failed to update status:", error);
        }
      };


    return (
        <>
            <Toaster />
            
            <Sidebar />
            <div className="content-wrapper">
            <div>
                
                <Modal className="modal" isOpen={isModalOpen} onClose={closeModal}>
                <section className="content-header">
                    <h1>
                        All Owner
                        <small>List of owner</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><Link to="/dashboard"><i className="fa fa-dashboard"></i> Home</Link></li>
                        
                        <li className="active">All Owner</li>
                    </ol>
                </section>
                </Modal>
            </div>
                <section className="content-header" >
                    <h1>
                        All Owner
                        <small>List of owner</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><Link to="/dashboard"><i className="fa fa-dashboard"></i> Home</Link></li>
                        
                        <li className="active">All Owner</li>
                    </ol>
                </section>

                
                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box" style={{height:'500px', overflowY: 'auto'}}>
                                <div className="box-header">
                                    <h3 className="box-title">List of All Owner</h3>
                                </div>
                                
                                <div className="box-body">
                                    <table id="example2" className="table table-bordered table-hover scrollable">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Email Address</th>
                                                <th>Phone Number</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            ownerLoading ? (
                                                <div className="overlay">
                                                    <i className="fa fa-refresh fa-spin"></i>
                                                </div>
                                            ) : ownerList?.map((item, index) => {
                                              return (
                                                        <tr key={index+1}>
                                                            <td>{index+1}</td>
                                                            <td>
                                                                <div className="user-block" key={index+1}>
                                                                    <img className="img-circle" src={(item?.profile == 0) ? rootConfig.base_url+'uploads/no_image.png' : rootConfig.base_url+item?.profile} alt="User Image" />
                                                                    <span className="username"><Link to="#">{_.startCase(_.toLower(item?.name))}</Link></span>
                                                                    <span className="description">Register - {moment(item?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {item.email}
                                                            </td>
                                                            <td>
                                                                {formatPhoneNumber(item.phone)}
                                                            </td>
                                                            <td>
                                                            <SwitchComponent
                                                                checked={item?.status === "Active" ? true : false}
                                                                onChange={() => updateStatus(item?._id)}
                                                            />
                                                            </td>
                                                            <td>
                                                            <SwitchComponent
                                                                checked={item?.suspend === "Active" ? true : false}
                                                                onChange={() => updateSuspend(item?._id)}
                                                            />
                                                            </td>
                                                            <td>
                                                                
                                                                <Link to={`/dashboard/owner/${item._id}`} type="button" className="btn btn-default btn-sm" 
                                                                // onClick={() => handleShowItem(item)} 
                                                                >
                                                                    <i className="fa fa-eye"></i>&nbsp;
                                                                        Show
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="box-footer">
                                    <div className="pagination-container">
                                        <nav aria-label="Page navigation">
                                            <ul className="pagination justify-content-center">
                                                <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                                    <button
                                                        className="page-link"
                                                        onClick={handlePrevious}
                                                        disabled={currentPage === 1}
                                                    >
                                                        Previous
                                                    </button>
                                                </li>
                                                <li className="page-item disabled">
                                                    <span className="page-link">
                                                        Page {currentPage} of {totalPages}
                                                    </span>
                                                </li>
                                                <li
                                                    className={`page-item ${currentPage === totalPages ? "disabled" : ""
                                                        }`}
                                                >
                                                    <button
                                                        className="page-link"
                                                        onClick={handleNext}
                                                        disabled={currentPage === totalPages}
                                                    >
                                                        Next
                                                    </button>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}